export default {
	BASE_STORAGE: process.env.REACT_APP_BASE_STORAGE,
	LEVELS: {
		ADMIN: 1,
		MODERATOR: 2,
		SELLER: 3
	},
	PLAN_PAYMENT_STATUS: {
		PAID: 1,
		TO_CONFIRM: 2,
		REJECTED: 3
	},
	ACTIVE: 1,
  	INACTIVE: 0,
}