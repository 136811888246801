import React from 'react';
import { Pagination, Table, Input, Button, Select, Tooltip, Icon, Modal } from 'components';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Constants, Globals, Print, Socket, SocketEvents } from 'utils';
import { UserService } from 'services';
import moment from 'moment';
import ModalCreateUser from './modal-create-user';
import ModalChangeConversion from './modal-change-conversion';
import ModalSendNotification from './modal-send-notification';
import Header from 'screens/header';
import { DangerIcon } from 'assets/icons';

interface LocationProps {
	page: number | undefined;
	form: any
}

interface Props extends RouteComponentProps<{},{},LocationProps> {
	dispatch: any;
	user: any;
}

const INITIAL_STATE = {
	name: '',
	status: ''
}

const STATUS = {
	ACTIVE: '1',
	INACTIVE: '0'
}

class Users extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		form: INITIAL_STATE,
		header: [
			'ID',
			'Nombre',
			'Email',
			'Plan',
			'Fecha de Corte',
			'Estatus',
			'Acceso API',
			'Referido Por',
			'Último Ingreso',
			'Acciones',
			...(this.props.user.level_id !== 1 ? ['Borrado de Datos'] : [])
		],
		visible: false,
		user: null,
		plans: [],
		global_conversion: null,
		visible_conversion: false,
		admin_functionalities: [],
		users_registered: 0,
		users_active: 0,
		visible_notification: false,
		user_logged: this.props.user,
	}

	async componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reporte de Usuarios',
				onBack: null
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await UserService.get({
			user_id: this.state.user_logged.id,
			page: this.state.page,
			withoutLoading,
			...this.state.form
		});
		this.setState({
			data: res.users.data,
			last_page: res.users.last_page,
			plans: res.plans,
			global_conversion: res.global_conversion,
			admin_functionalities: res.admin_functionalities,
			users_active: res.users_active || 0,
			users_registered: res.users_registered || 0
		});
	}

	block = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm(`¿Desea ${ item.superadmin_block ? 'DESBLOQUEAR' : 'BLOQUEAR' } al usuario?`,async () => {
			await UserService.changeBlock({
				user_id: item?.id
			});
			Socket.emit(SocketEvents.UPDATE_SUPERADMIN,{
				admin_id: item?.id
			});
			this.load(true);
		});
	}

	setNegativeStock = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm(`¿Desea ${ item.config?.negative_stock === Constants.ACTIVE ? 'INACTIVAR' : 'ACTIVAR' } el stock ilimitado? (permite stock negativo)`,async () => {
			await UserService.setNegativeStock({
				user_id: item?.id
			});
			Socket.emit(SocketEvents.UPDATE_SUPERADMIN,{
				admin_id: item?.id
			});
			this.load(true);
		});
	}

	create = () => {
		this.setState({
			visible: true
		});
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			user: item
		});
	}

	setSynchronizer = (e: any, item: any) => {
		e.preventDefault();
		const status = item?.synchronizer === 1 ? 'Inactivar' : 'Activar';
		Globals.confirm(`¿Deseas ${status} el acceso al API?`,async () => {
			await UserService.setSynchronizer({
				admin_id: item?.id
			});
			Globals.showSuccess("Se ha cambiado el acceso al API");
			this.load(true);
		});
	}

	copyApiToken = async (token: string) => {
		try {
			await navigator.clipboard.writeText(token);
			console.log('Token copied successfully');
			Globals.showSuccess("API Token copiado");
		} catch (err) {
			console.log('Error al copiar el texto: ', err);
			Globals.showError("Ocurrió un error inesperado");
		}
	}
			

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	onClose = () => {
		this.setState({
			visible: false,
			user: null
		},() => this.load(true));
	}

	onCloseSendNotification = () => {
		this.setState({
			visible_notification: false
		});
	}

	onCloseConversion = (global_conversion = null) => {
		this.setState({
			visible_conversion: false
		},() => {
			if (global_conversion) {
				this.setState({
					global_conversion
				});
			}
		});
	}

	exportXLS = async () => {
		const res: any = await UserService.download({
			...this.state.form,
			user_id: this.state.user_logged?.id,
		});
	    Print(Constants.BASE_STORAGE + res.url);
	}

	deleteData = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm(`¿Eliminar los datos del usuario?`,async () => {
			Globals.confirm(`¿Esta opción no se puede deshacer. ¿Esta seguro de eliminar los datos del usuario?`,async () => {
				await UserService.deleteData({
					user_id: item?.id
				});
				Socket.emit(SocketEvents.UPDATE_SUPERADMIN,{
					admin_id: item?.id
				});
				Globals.showSuccess("Se ha eliminado los datos del usuario");
				this.load(true);
			});
		});
	}
	
	render() {
		const { visible, visible_conversion, visible_notification, user_logged } = this.state;

		return (
			<div id="admin-users">
				<Modal
		          className="modal-create-user"
		          visible={ visible }
		          title={ (this.state.user ? 'Editar' : 'Crear') + ' usuario' }
		          onClose={ () => this.onClose() }
		        >
		          <ModalCreateUser
		            user={ this.state.user }
		            plans={ this.state.plans }
		            admin_functionalities={ this.state.admin_functionalities }
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<Modal
		          className="modal-create-user"
		          visible={ visible_conversion }
		          title="Cambiar conversión"
		          onClose={ () => this.onCloseConversion() }
		        >
		          <ModalChangeConversion
		            global_conversion={ this.state.global_conversion }
		            onClose={ (global_conversion: any = null) => this.onCloseConversion(global_conversion) } />
		      	</Modal>

		      	<Modal
		          className="modal-create-user"
		          visible={ visible_notification }
		          title="Enviar Notificación"
		          onClose={ () => this.onCloseSendNotification() }
		        >
		          <ModalSendNotification
		            onClose={ () => this.onCloseSendNotification() } />
		      	</Modal>

		      	<Header>
		      		<li onClick={ () => {
						this.setState({
							visible_notification: true
						});
					} }>
						Enviar Notificación
					</li>
					<li onClick={ () => {
						this.setState({
							visible_conversion: true
						});
					} }>
						Cambiar conversión
					</li>
					<li onClick={ () => this.create() }>
						Nuevo Usuario
					</li>
					<li onClick={ () => this.props.history.replace('/payment-report') }>
						Reporte de Pagos
					</li>
		      	</Header>

				<div className="row row-filter">
					<div className="col-md-3">
						<Input
							searchRight
							value={ this.state.form.name }
							name="name"
							label="Búsqueda por nombre o email"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<Select
							color="white"
							name="status"
							label="Estatus"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Todos"
							value={ this.state.form.status }
							options={ [
								{ value: STATUS.ACTIVE, label: 'Activos' },
								{ value: STATUS.INACTIVE, label: 'Inactivos' },
							] } />
					</div>
				</div>

				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}

				<div className="row row-total">
					<div className="col-md-4">
						<p><strong>Usuarios registrados:</strong> { this.state.users_registered }</p>
					</div>
					<div className="col-md-4">
						<p><strong>Usuarios activos:</strong> { this.state.users_active }</p>
					</div>
					<div className="col-md-4 text-right">
						<Button onClick={ () => this.exportXLS() }>
							Exportar XLS
						</Button>
					</div>
					<div className="col-md-8">
						<div className="row">
							{
								this.state.plans.map((item: any) => {
									return (
										<div className="col-md-3">
											<p className="item-plan"><strong>{ item.name }:</strong> { item.total }</p>
										</div>
									)
								})
							}
						</div>
					</div>
				</div>

				<Table data={ this.state.data.length } title="" header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.id }</td>
								<td>{ i.name }</td>
								<td>{ i.email }</td>
								<td>{ i.plan_user?.plan?.name }</td>
								<td className={ i?.plan_user?.last_payment != null ? (moment().format('YYYY-MM-DD') <= moment(i?.plan_user?.last_payment?.next_payment || i?.trial_expiration).format('YYYY-MM-DD') ? '' : 'danger') : 'danger' }>{ i?.plan_user?.last_payment != null || i?.trial_expiration != null ? moment(i?.plan_user?.last_payment?.next_payment || i?.trial_expiration).format('DD/MM/YYYY') : 'Sin configurar' }</td>
								<td className={ i?.plan_user?.last_payment != null && !i.superadmin_block ? (moment().format('YYYY-MM-DD') <= moment(i?.plan_user?.last_payment?.next_payment || i?.trial_expiration).format('YYYY-MM-DD') ? '' : 'danger') : 'danger' }>{ i.superadmin_block ? 'Bloqueado' : i?.plan_user?.last_payment != null || i?.trial_expiration != null ? (moment().format('YYYY-MM-DD') <= moment(i?.plan_user?.last_payment?.next_payment || i?.trial_expiration).format('YYYY-MM-DD') ? 'Activo' : 'Inactivo') : 'Inactivo' }</td>
								<td className={`synchronizer ${i.synchronizer !== 1 ? 'danger' : ''}`}>
									{i.synchronizer === 1 ? 'Activo' : 'Inactivo'}
								</td>
								<td>{i.referrer ? i.referrer?.name : ''}</td>
								<td>{ i?.last_session ? moment(i?.last_session).format('DD/MM/YYYY HH:mm') : '' }</td>
								<td>
									{user_logged.level_id !== 1 && (
										<Tooltip title="Editar">
											<a className="link-icon" href="#" onClick={(e) => this.edit(e, i)}>
												<Icon name="edit" />
											</a>
										</Tooltip>
									)}
									<Tooltip title={ i.superadmin_block ? 'Desbloquear' : 'Bloquear' }>
										<a className="link-icon link-danger" href="#" onClick={ (e: any) => this.block(e,i) }>
											<Icon name={ i.superadmin_block ? 'unlock' : 'lock' } />
										</a>
									</Tooltip>
									<Tooltip title={i.synchronizer === 1 ? 'Inactivar acceso a API' : 'Activar acceso a API'}>
										<a className="link-icon" href="#" onClick={ (e: any) => this.setSynchronizer(e,i) }>
											<Icon name={i.synchronizer === 1 ? 'lock' : 'unlock'} />
										</a>
									</Tooltip>
									
									{(i.synchronizer === 1 && i.user_access !== null) && (
										<Tooltip title="Copiar API Token">
											<a 
												className="link-icon" 
												href="#" 
												onClick={(e: any) => this.copyApiToken(i.user_access?.token)}
											>
												<Icon name={'copy'} />
												{/* <Icon name={'key'} /> */}
											</a>
										</Tooltip>
									)}

									<Tooltip 
										title={
											i.config && i.config.negative_stock === Constants.ACTIVE ? "Inventario ilimitado: Activo. Click para desactivar"
											: "Inventario ilimitado: Inactivo. Click para activar"
										}
									>
										<a 
											className={i.config && i.config.negative_stock === Constants.ACTIVE ? 'text-success' : 'text-danger'}
											href="#" 
											onClick={(e: any) => this.setNegativeStock(e, i)}
										>
											<Icon name={i.config && i.config.negative_stock === Constants.ACTIVE ? 'toggle-on' : 'toggle-off'} />
										</a>
									</Tooltip>
								</td>
								{user_logged.level_id !== 1 && (
									<td>
										<Tooltip title="Borrar datos de usuario (Peligro)">
											<a className="link-icon link-danger" href="#" onClick={ (e: any) => this.deleteData(e,i) }>
												<img src={ DangerIcon } />
											</a>
										</Tooltip>
									</td>
								)}
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

// Mapea el estado `user` al componente como una prop
const mapStateToProps = (state: any) => ({
	user: state.user,
});

// Conecta el componente al store de Redux
export default connect(mapStateToProps)(Users);

//export default connect()(Users);