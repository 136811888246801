import { Api } from 'utils';

class UserService {
	
	static get = async (params?: any) => Api.createResponse('superadmin/users/get',params);
	static create = async (params?: any) => Api.createResponse('superadmin/users/create',params);
	static update = async (params?: any) => Api.createResponse('superadmin/users/update',params);
	static updateConversion = async (params?: any) => Api.createResponse('superadmin/users/update-conversion',params);
	static download = async (params?: any) => Api.createResponse('superadmin/users/download',params);
	static changeBlock = async (params?: any) => Api.createResponse('superadmin/users/change-block',params);
	static deleteData = async (params?: any) => Api.createResponse('superadmin/users/delete-data',params);
	static setSynchronizer = async (params?: any) => Api.createResponse('superadmin/users/setsynchronizer',params);
	static setNegativeStock = async (params?: any) => Api.createResponse('superadmin/users/set-negativestock',params);
	
}

export default UserService;